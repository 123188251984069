import logger from 'saddlebag-logger';

import withErrorBoundary from '../ErrorBoundary';

import TopPanel from './TopPanel';
import branch from './branch';

import type { MobileLandingProps } from '@skyscanner-internal/falcon-common-types/types/MobileLanding';

import STYLES from './MobileLanding.module.scss';

const BRANCH_KEY = 'key_live_bglZn2p4MsvqhjauKrknLmopyEkpp8FS';

const MobileLanding = ({
  desktopUrl,
  images,
  scanForAppProps,
  strings,
}: MobileLandingProps) => {
  try {
    branch.init &&
      branch.init(
        BRANCH_KEY,
        {
          make_new_link: false,
        },
        (error: string, data: { [key: string]: any }) => {
          if (error) {
            logger.logError(new Error(error), {
              message: 'Failed to init branch',
            });
          }
        },
      );
  } catch (error: any) {
    logger.logError(error, {
      message: 'Failed to init branch',
    });
  }

  return (
    <div id="mobile-landing" className={STYLES.MobileLanding}>
      <TopPanel
        description={strings['mobile-apps_oneBigAdventure_description']}
        images={images}
        scanForAppProps={{ ...scanForAppProps, strings }}
        title={strings['mobile-apps_oneBigAdventure_title']}
        desktopUrl={desktopUrl}
      />
    </div>
  );
};

const MobileLandingWithErrorBoundary = withErrorBoundary(
  MobileLanding,
  'mobile-landing-component',
);
export default MobileLandingWithErrorBoundary;
