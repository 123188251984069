import BpkImage, {
  withLazyLoading,
  withLoadingBehavior,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link';

import type { DesktopUrl } from '@skyscanner-internal/falcon-common-types/types/MobileLanding';

import STYLES from './AppStoreWidget.module.scss';

type AppStoreWidgetProps = {
  imageSrc: {
    appStore: string;
    playStore: string;
  };
  deeplinkUrl: string;
  desktopUrl: DesktopUrl;
};

const documentIfExists = typeof window !== 'undefined' ? document : null;
const FadingLazyLoadedImage = withLoadingBehavior(
  withLazyLoading(BpkImage, documentIfExists),
);

const AppStoreWidget = ({
  deeplinkUrl,
  desktopUrl,
  imageSrc,
}: AppStoreWidgetProps) => (
  <div className={STYLES.AppStoreButton__container}>
    <BpkLink
      href={`${deeplinkUrl}${
        deeplinkUrl.indexOf('?') >= 0 ? '&' : '?'
      }$desktop_url=${desktopUrl.apple}`}
      className={STYLES.AppStoreButton__imageLink}
    >
      <div className={STYLES.AppStoreButton__image}>
        <FadingLazyLoadedImage
          altText="App Store Button"
          src={imageSrc.appStore}
          aspectRatio={960 / 321}
        />
      </div>
    </BpkLink>
    <BpkLink
      href={`${deeplinkUrl}${
        deeplinkUrl.indexOf('?') >= 0 ? '&' : '?'
      }$desktop_url=${desktopUrl.google}`}
      className={STYLES.AppStoreButton__imageLink}
    >
      <div className={STYLES.AppStoreButton__image}>
        <FadingLazyLoadedImage
          altText="Play Store Button"
          src={imageSrc.playStore}
          aspectRatio={565 / 171}
        />
      </div>
    </BpkLink>
  </div>
);

export default AppStoreWidget;
