import { PureComponent, createRef } from 'react';
import type { RefObject } from 'react';

import Color from 'color';
import QRCode from 'qrcode';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import { withButtonAlignment } from '@skyscanner/backpack-web/bpk-component-icon';
import HelpIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/help-circle';
import BpkImage from '@skyscanner/backpack-web/bpk-component-image';
import BpkPopover from '@skyscanner/backpack-web/bpk-component-popover';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';
import { colorSkyBlue } from 'bpk-tokens/tokens/base.es6';

import EVENTS from '../events';
import STRINGS from '../strings';
import { trackEvent } from '../utils';

import type { StringTranslations } from '@skyscanner-internal/falcon-common-types/types/components';

import STYLES from './ScanQR.module.scss';

type ScanQRProps = {
  deeplinkUrl: string;
  strings: StringTranslations;
};

type ScanQRState = {
  isPopoverOpen?: boolean;
  uri: string;
};

const backpackColorToHex = (color: string) => Color(color).hex();
const azureColor = backpackColorToHex(colorSkyBlue);

const AlignedHelpIcon = withButtonAlignment(HelpIcon);

class ScanQR extends PureComponent<ScanQRProps, ScanQRState> {
  popoverTarget: RefObject<HTMLDivElement>;

  constructor(props: ScanQRProps) {
    super(props);
    this.popoverTarget = createRef();
    this.state = {
      uri: '',
      isPopoverOpen: false,
    };
  }

  componentDidMount() {
    this.createAndUpdateQRCode();
  }

  componentDidUpdate(prevProps: ScanQRProps) {
    if (prevProps.deeplinkUrl !== this.props.deeplinkUrl) {
      this.createAndUpdateQRCode();
    }
  }

  openPopover = () => {
    this.setState({
      isPopoverOpen: true,
    });
  };

  closePopover = () => {
    this.setState({
      isPopoverOpen: false,
    });
  };

  createAndUpdateQRCode() {
    return QRCode.toDataURL(this.props.deeplinkUrl, {
      color: {
        dark: azureColor,
      },
    })
      .then((uri) => {
        this.setState({ uri });
        trackEvent(EVENTS.GENERATE_QR_CODE_SUCCESS);
      })
      .catch((error) =>
        trackEvent(EVENTS.GENERATE_QR_CODE_ERROR, {
          error,
        }),
      );
  }

  renderHelp = (text: string) => (
    <div ref={this.popoverTarget}>
      <BpkButton link onClick={this.openPopover}>
        <AlignedHelpIcon fill={colorSkyBlue} />
        &nbsp;
        {text}
      </BpkButton>
    </div>
  );

  renderHelpPopover = (text: string, description: string, close: string) => (
    <BpkPopover
      closeButtonProps={{ 'data-testid': 'close-button' }}
      closeButtonText={close}
      id="scanrqrcode-help-popover"
      isOpen={this.state.isPopoverOpen}
      label=""
      onClose={this.closePopover}
      placement="bottom"
      target={this.renderHelp(text)}
    >
      <BpkText>{description}</BpkText>
    </BpkPopover>
  );

  render() {
    const { strings = STRINGS } = this.props;
    const { uri } = this.state;

    return (
      <div id="help-popover-container" className={STYLES.ScanQR}>
        <div className={STYLES.ScanQR__leftColumn}>
          <BpkText textStyle={TEXT_STYLES.label1}>
            {strings.falcon_component_ScanForApp_scanQRCodeDescription}
          </BpkText>
          {this.renderHelpPopover(
            strings.falcon_component_ScanForApp_scanQRCodeHelp,
            strings.falcon_component_ScanForApp_scanQRCodeHelpDescription,
            strings.falcon_component_ScanForApp_scanQRCodeClose,
          )}
        </div>
        <div className={STYLES.ScanQR__rightColumn}>
          <BpkImage
            altText={strings.falcon_component_ScanForApp_scanQRCode}
            aspectRatio={1}
            src={uri}
          />
        </div>
      </div>
    );
  }
}

export default ScanQR;
